//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "InlandOrderInfo",
  data() {
    return {
      defaultText: "-----"
    };
  },
  props: ["detailData", "payType"],
  watch: {},
  methods: {
    goPage(val) {
      if (!val) return;
      this.$router.push({
        name: "air-ticket-admin-international-list-detail",
        query: { ordersNo: val }
      });
    },
    goAirDetail() {
      this.$router.push({
        name: "air-ticket-admin-airDetails",
        query: { ordersNo: this.detailData.orderNo }
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.$router.go(0);
        }, 100);
      });
    }
  }
};
