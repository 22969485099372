import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import ChangeInfo from "../../components/changeOrderInfo";
import changeOrderDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_changeDetail";

import PaymentMethodSelector002 from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.3.1/payment-method-selector-002/index.vue';

import changeLog from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_order_change_log_list";
import payment from "@/lib/data-service/flight/newFligthApi/payment_params";

import flight_domestic_buyer_manage_order_detail from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail';

export default {
    name: "changPayment",
    data() {
        return {
            detailData: null,
            activeName: 'first',
            loading: true,
            orderNo: '',
            dialogVisible: false,
            payType: '',
            tableData: [],
            psgNoList: [],
            logListForm: {
                changeId: '',
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            }
        };
    },
    components: {ChangeInfo, PaymentMethodSelector002},
    methods: {
        getDetail() {
            let psgNoList = typeof this.psgNoList === 'object' ? this.psgNoList : [this.psgNoList]
            changeOrderDetail({chaOrderNo: this.orderNo, psgNoList: psgNoList}).then(res => {

                this.detailData = res.detailResult;
                this.detailData.img = res.detailResult.url.split(",")[0];
                this.detailData.imgList = res.detailResult.url.split(",");
                this.loading = false
                this.payInit()
            })
        },
        handleCurrentChange(val) {
            this.logListForm.currentPage = val
            this.getLog()
        },
        handleSizeChange(val) {
            this.logListForm.pageSize = val
            this.getLog()
        },
        getLog() {
            changeLog(this.logListForm).then(res => {
                console.log('改签', res)
                this.tableData = res.pageResult.pageData;
                this.logListForm.totalCount = res.pageResult.totalCount
            })
        },
        payInit() {
            let orderInfo
            let __this = this
            orderInfo = payment({type: 12})
            orderInfo.then(res => {
                __this.$refs.aPaymentMethodSelector002.init({
                    mock_data: {
                        enable: false,
                    },
                    get_params() {
                        const detail = __this.detailData;
                        let segmentList = detail.oldSegmentList;
                        if (!_.isArray(segmentList)) {
                            const msg = `get_params parameter error, code=001`;
                            console.error(msg);
                            console.trace();
                            return Q.reject({msg: msg});
                        }
                        if (segmentList.length <= 0) {
                            const msg = `get_params parameter error, code=002`;
                            console.error(msg);
                            console.trace();
                            return Q.reject({msg: msg});
                        }
                        const first_segment = segmentList[0];

                        const p = {
                            companyId: res.companyId,
                            clubId: res.clubId,
                            orderNo: __this.orderNo,
                            isUseCredit: __this.detailData.creditState || false,
                            businessContent: res.businessContent,
                            subject: first_segment.sCityName + "-" + first_segment.eCityName + " （改签）",
                            orderAmount: __this.detailData.changePayAmount,
                            clientType: 1,//客户端类型：1.b2b，2.b2c，3.差旅,默认为1
                        };

                        return Q.resolve(p);
                    },

                    count_down: {
                        enable: false,
                        // pay_remaining_seconds: 0,
                    },

                    get_qing_song_fu_data() {
                        return Q.when()
                            .then(function () {
                                const detailData = __this.detailData;
                                const orderNo = detailData.orderNo;
                                return flight_domestic_buyer_manage_order_detail({
                                    orderNo,
                                });
                            }).then(function (data) {
                                // return Q.resolve({
                                //     relaxPay: -1,
                                // });
                                const relaxPayResponse = data.relaxPayResponse;
                                const d = {};
                                d.ableAmount = 0;
                                d.countAmount = 0;
                                d.depositMount = 0;
                                d.finalPaymentTime = ``;
                                d.needMount = 0;
                                d.relaxPay = -1;
                                d.scharge = 0;
                                d.usableAmount = 0;

                                if (!relaxPayResponse) {
                                    return Q.resolve(d);
                                }
                                extend(d, relaxPayResponse);

                                return Q.resolve(d);
                            })
                            .catch(function (ex) {
                                if (ex) {
                                    const enable_log_error = !ex.disable_log_error;
                                    if (enable_log_error) {
                                        console.error(ex);
                                        console.trace();
                                        if (ex.stack) {
                                            console.error(ex.stack);
                                        }
                                    }
                                }
                                return Q.reject(ex);
                            })
                            ;
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        // __this.$router.go(-1)
                        __this.$message({type: "success", message: "支付成功！"})
                        __this.$router.replace({
                            name: 'air-ticket-admin-change-order-list'
                        })
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        __this.$message({type: "warning", message: "支付失败，请重新支付！"})
                    },
                });
            })
        },
    },
    mounted() {},
    activated() {
        this.orderNo = this.$route.query.ordersNo
        this.logListForm.changeId = this.$route.query.ordersNo
        this.payType = this.$route.query.pay
        this.psgNoList = this.$route.query.psgNoList
        this.getDetail()
        this.getLog()
    }
};
